<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    bottom
    left
    max-width="90%"
    min-width="275"
    offset-x
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-card
        id="settings"
        class="py-2 px-4"
        color="#0000004D"
        dark
        flat
        style="position: fixed; top: 164px; right: -25px; text-align: center"
        width="100"
        v-bind="attrs"
        v-on="on"
      >
        <div
          style="margin-right: 25px"
          class="mb-2"
        >
          {{ languages.find(item=>item.value === $route.meta.lang).name.toUpperCase() }}
        </div>
        <base-img
          max-width="50px"
          :src="require(`@/assets/flags/${$route.meta.lang}.svg`)"
          class="mb-2"
          style="cursor: pointer;"
        />
      </v-card>
    </template>

    <v-card class="py-2">
      <base-title
        align="center"
        :title="translations.chooseLanguage"
        space="0"
      />
      <v-card-text>
        <v-item-group
          v-model="$vuetify.theme.themes.light.primary"
          class="d-flex justify-center"
        >
          <v-item
            v-for="lang in languages"
            :key="lang.value"
            :value="lang.value"
          >
            <template v-slot="{ active, toggle }">
              <base-img
                max-width="50px"
                :src="require(`@/assets/flags/${lang.value}.svg`)"
                class="ma-4"
                :class="{active}"
                style="cursor: pointer;"
                @click.stop="changeLang(lang)"
              />
            </template>
          </v-item>
        </v-item-group>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
  import { mapMutations, mapState } from 'vuex'
  import { SET_LANG } from '../../store/mutation-types'
  import { PL, EN } from '@/constants/lang.js'
  import { STORE_NAMESPACE_ALL } from '@/App.vue'

  export default {
    name: 'HomeSettings',

    data () {
      return {
        languages: [
          { name: 'en', value: EN },
          { name: 'pl', value: PL },
        ],
        menu: false,
      }
    },
    computed: {
      ...mapState(STORE_NAMESPACE_ALL, [
        'translations',
      ]),
    },
    methods: {
      ...mapMutations({
        setLanguage: SET_LANG,
      }),
      changeLang (lang) {
        if (lang.value !== this.$route.meta.lang) {
          const name = `${this.$route.name.split('-')[0]}-${lang.name}`

          this.$router.push({ name, meta: { lang: lang.value } })
        }
      },
    },
  }
</script>
